import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { DashboardGridModule } from '@x/dashboard/grid';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { DebitWalletPaymentDialogComponent } from './components/debit-wallet-payment-dialog/debit-wallet-payment-dialog.component';
import { WalletFormDialogComponent } from './components/wallet-form-dialog/wallet-form-dialog.component';
import { WalletRefundDialogComponent } from './components/wallet-refund-dialog/wallet-refund-dialog.component';
import { WalletTableComponent } from './components/wallet-table/wallet-table.component';
import { WalletTransactionFormDialogComponent } from './components/wallet-transaction-form-dialog/wallet-transaction-form-dialog.component';
import { WalletTransactionTableComponent } from './components/wallet-transaction-table/wallet-transaction-table.component';
import { WalletDialogService } from './services/wallet-dialog.service';

@NgModule({
  declarations: [
    DebitWalletPaymentDialogComponent,
    WalletRefundDialogComponent,
    WalletFormDialogComponent,
    WalletTransactionFormDialogComponent,
    WalletTableComponent,
    WalletTransactionTableComponent,
  ],
  providers: [WalletDialogService],
  exports: [
    DebitWalletPaymentDialogComponent,
    WalletTableComponent,
    WalletTransactionTableComponent,
  ],
  imports: [
    CommonModule,
    DashboardCoreModule,
    DashboardFormModule,
    DashboardGridModule,
    EcommerceAdminCoreModule,
  ],
})
export class WalletsSharedModule {}
