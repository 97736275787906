import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { IWalletTransactionObject } from '@x/ecommerce/domain-client';
import { WalletTransactionFilterInput } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-wallet-transaction-table',
  templateUrl: './wallet-transaction-table.component.html',
  styleUrls: ['./wallet-transaction-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletTransactionTableComponent {
  @Input() view: DataTableView<IWalletTransactionObject, WalletTransactionFilterInput, any, number>;
  @Input() currency: string;
}
