<x-data-table [view]="view">
  <div *xDataColumnDef></div>

  <ng-container *xDataColumnDef="'id'; let row">
    {{ row.id }}
  </ng-container>

  <ng-container *xDataColumnDef="'state'; let row">
    <x-pill>
      {{ row.state + '' | titlecase }}
    </x-pill>
  </ng-container>

  <ng-container *xDataColumnDef="'amount'; let row">
    {{ row.amount | money: currency }}
  </ng-container>

  <ng-container *xDataColumnDef="'balance'; let row">
    {{ row.balance | money: currency }}
  </ng-container>

  <ng-container *xDataColumnDef="'description'; let row">
    {{ row.description }}
  </ng-container>

  <ng-container *xDataColumnDef="'order'; let row">
    <x-order-pill
      *ngIf="row.order"
      [order]="row.order"
      [matMenuTriggerFor]="orderMenu"
      [matMenuTriggerData]="{ $implicit: row }"
    ></x-order-pill>
  </ng-container>

  <mat-menu #orderMenu>
    <ng-template matMenuContent let-transaction>
      <a mat-menu-item [routerLink]="['/admin/orders/detail/', transaction.order.id]">View Order</a>
    </ng-template>
  </mat-menu>

  <ng-container *xDataColumnDef="'finalizedAt'; let row">
    {{ row.finalizedAt | datetime: 'medium' }}
  </ng-container>
</x-data-table>
