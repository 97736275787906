<x-data-table [view]="view" [selectable]="false">
  <div *xDataColumnDef="'id'; let row">{{ row.id }}</div>

  <div *xDataColumnDef="'user'; let row">
    <x-user-pill
      *ngIf="row.user"
      [user]="row.user"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.user }"
    ></x-user-pill>
  </div>

  <mat-menu #rowUserMenu>
    <ng-template matMenuContent let-user>
      <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
    </ng-template>
  </mat-menu>

  <div *xDataColumnDef="'walletType'; let row">{{ 'WalletType.' + row.walletType | l }}</div>
  <div *xDataColumnDef="'currency'; let row">{{ row.currency }}</div>
  <div *xDataColumnDef="'balance'; let row">{{ row.balance | money: row.currency }}</div>

  <div *xDataColumnDef="'timestamps'; let row">
    <x-timestamp-column [row]="row"></x-timestamp-column>
  </div>

  <a
    x-data-button
    *xDataAction="'detail'; let row; primary: true"
    [routerLink]="['/admin/wallets/detail', row.id]"
    label="View Wallet"
    icon="search"
  ></a>

  <button
    x-data-button
    *xDataAction="'credit'; let row"
    label="Credit"
    icon="credit-card"
    (click)="creditWallet(row.id)"
  ></button>
</x-data-table>
