import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { WalletRowObject, WalletService } from '@x/ecommerce/domain-client';
import { WalletFilterInput } from '@x/schemas/ecommerce';
import { WalletDialogService } from '../../services/wallet-dialog.service';

@Component({
  selector: 'x-wallet-table',
  templateUrl: './wallet-table.component.html',
  styleUrls: ['./wallet-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-wallet-table',
  },
})
export class WalletTableComponent {
  @Input() view: DataTableView<WalletRowObject, WalletFilterInput, any, number>;

  constructor(
    private walletDialogService: WalletDialogService,
    private walletService: WalletService,
  ) {}

  creditWallet(walletId: number) {
    this.walletDialogService
      .openTransactionWalletDialog({ walletId })
      .afterClosed()
      .subscribe((result) => {
        if (!(result && result.amount)) return;
        this.view
          .mutateRow(walletId, (walletId) => {
            return this.walletService.credit({
              walletId,
              amount: result.amount,
              description: result.description,
            });
          })
          .subscribe();
      });
  }
}
